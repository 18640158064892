import axios from 'axios'
import { decrypt, API_KEY } from '../components/utils/decrypt'
import { jwtDecode } from 'jwt-decode'
import { determineBrandFromDomain, localeMap, supportedLocales } from '../components/utils/helpers'

let API_BASE_URL = null

export const initializeApi = async () => {
    try {
        const encryptedNcpWebApi = window.ncpWebApi
        API_BASE_URL = await decrypt(encryptedNcpWebApi, API_KEY)

        const storageData = JSON.parse(localStorage.getItem('appData')) || {}

        storageData.ncpWebApi = encryptedNcpWebApi

        localStorage.setItem('appData', JSON.stringify(storageData))

    } catch (error) {
        console.error('Error fetching API BASE URL:', error);
        throw error;
    }
}

const getJwt = async (requestBody, csrfToken, captchaToken) => {
    try {
        const response = await axios.put(`${API_BASE_URL}/auths`, requestBody, {
            headers: {
                'X-CSRF-Token': csrfToken,
                'X-RECAPTCHA': captchaToken,
                'Content-Type': 'application/json',
            }
        })
        return response.data.token
    } catch (err) {
        console.error('Error fetching jwt: ', err)
        throw err;
    }
}

export const getConfig = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/config`, {
            headers: {
                'x-api-key': API_KEY,
                'Content-Type': 'application/json',
            }
        })
        return response.data
    } catch (err) {
        console.error('Error fetching config: ', err)
        throw err;
    }
}

export const getLoginUrl = async (brandId) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/config/loginurl?brand_id=${brandId}`, {
            headers: {
                'x-api-key': API_KEY,
                'Content-Type': 'application/json',
            }
        })
        return response.data
    } catch (err) {
        console.error('Error fetching config: ', err)
        throw err;
    }
}

export const loginUser = async (requestBody, csrfToken, returnTo, captchaToken, instanceSubDomain) => {
    try {
        const jwt = await getJwt(requestBody, csrfToken, captchaToken)
        const decodedJwt = jwtDecode(jwt)

        const jwtLocale = decodedJwt.locale
        const localeString = jwtLocale ? localeMap[jwtLocale] : null
        const appData = JSON.parse(localStorage.getItem('appData'))

        const brand = appData.brand

        if (!supportedLocales[brand].includes(localeString)) {
            const error = new Error('Access Denied')
            error.code = 401
            throw error;
        }

        let updatedReturnTo = returnTo
        if (localeString) {
            updatedReturnTo = returnTo.replace(/\/hc\/[a-z]{2}-[a-z]{2}$/, `/hc/${localeString}`)
        }

        const base64EncodedAppData = btoa(encodeURIComponent(JSON.stringify(appData)));
        const returnToWithAppData = `${updatedReturnTo}?appData=${base64EncodedAppData}`;
        const zendeskLoginUrl = `${instanceSubDomain}/access/jwt?return_to=${encodeURIComponent(returnToWithAppData)}`;
        window.location.href = `${zendeskLoginUrl}&jwt=${jwt}`;
    } catch (err) {
        console.error('Error loggin in: ', err)
        throw err;
    }
}

export const requestResetPassword = async (requestBody, csrfToken, captchaToken) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/resets/passwords`, requestBody, {
            headers: {
                'X-CSRF-Token': csrfToken,
                'X-RECAPTCHA': captchaToken,
                'Content-Type': 'application/json',
            }
        })
        return response
    } catch (err) {
        console.error('Error requesting for reset password: ', err)
        throw err
    }
}

export const resetPassword = async (requestBody, csrfToken, token, captchaToken) => {
    try {
        const response = await axios.put(`${API_BASE_URL}/resets/passwords/${token}`, requestBody, {
            headers: {
                'X-CSRF-Token': csrfToken,
                'X-RECAPTCHA': captchaToken,
                'Content-Type': 'application/json',
            }
        })
        return response.data
    } catch (err) {
        console.error('Error resetting password: ', err)
        throw err
    }
}
