import React, { useState, useEffect } from 'react'
import './App.css';
import {
  useLocation,
  Navigate,
  Routes,
  Route
} from 'react-router-dom'
import LoginForm from './components/LoginForm';
import PasswordResetForm from './components/PasswordResetForm';
import NewPasswordForm from './components/NewPasswordForm';
import Header from './components/common/Header';
import { useLocale } from './components/common/LocaleContext'
import Footer from './components/common/Footer';
import InfoPanel from './components/common/InfoPanel';
import styled from 'styled-components'
import AuthPageContentContext from './components/common/AuthPageContext';
import { useStoryblok } from './components/common/StoryblokContext';
import { storyblokInit, apiPlugin } from "@storyblok/js";
import DomainContext from './components/common/DomainContext';
import { getConfig } from './api/ncpApiMethods';
import { decrypt, API_KEY } from './components/utils/decrypt';
import { captializeFirstLetter } from './components/utils/helpers';
import OverlayLoader from './components/common/OverlayLoader';
import useCachedStoryblok from './components/utils/customHooks/useCachedStoryblok';
import LoginTroubleshoot from './components/LoginTroubleshoot';
import NotFoundPage from './components/NotFoundPage';

const ContentContainer = styled.div`
display: flex;
flex-direction: column;
background: url(${props => props.$background && props.$background});
mix-blend-mode: multiply;

@media (max-width: 768px) {
  width: 100%;

  > * {
    flex: none;
    width: 100%;
  }
}
@media (max-width: 1049px) {
  flex-direction: column;
  justify-content: center;
}
`
const HeroContainer = styled.div`
display: flex;
flex-direction: row;
background-color: #333;

@media (max-width: 1049px) {
  flex-direction: column;
  justify-content: center;
  background: transparent;
  padding-bottom: ${props => props.$addPadding ? '150px' : ''}
}
`
const HeroContent = styled.div`
background: url(${props => props.$background && props.$background});
background-size: cover;
background-position: center;
background-color: rgba(51, 51, 51, 0.70);
background-blend-mode: overlay, normal;
display: flex;
flex-direction: row;
justify-content: center;
align-items: flex-end;
min-height: 360px;
overflow: hidden; /* Prevents content from spilling over */
word-wrap: break-word;

@media (min-width: 769px) {
  padding: 10% 5%;
  flex: 1;
  width: 100%;
  position: relative;
}
`
const HeroContentInner = styled.div`
display: flex;
padding: 8px 0px;
flex-direction: column;
align-items: flex-start;
z-index: 2;
min-width: 340px; 
width: 80%;

@media (max-width: 768px) {
  padding: 12% 5%;
}
@media (min-width: 1049px) {
  border-bottom: 3px solid #FFF;
  width: 100%;
  gap: 25px;
  padding: ${props => props.$isNotFound ? '0px' : '35px 0'};
}

@media (max-width: 593px) {
  padding: 20% 5%;
}
`
const HeroTitle = styled.div`
color: #FFF;
flex-shrink: 0;
font-family: Poppins;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: 34px;

@media (min-width: 593px) {
  font-size: 52px;
  line-height: 58px;
}
`
const HeroSubtitle = styled.div`
color: #FFF;
flex-shrink: 0;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 24px; 

@media (min-width: 593px) {
  font-size: 26px;
  line-height: 24px; 
}
`
const AppContent = styled.div`
display: flex;
padding: 10% 5%;
background: ${props => props.$isNcp ? '#1D9B81' : '#3A71EE'};
width: 100%;
justify-content: center;
align-items: center;
min-height: 500px;
flex: 1;
position: relative;

@media (max-width: 1049px) {
  background: transparent;
}
`

function App() {
  const location = useLocation()
  const { storyblokApi, setStoryblokApi } = useStoryblok()
  const { locale, brand, contextPath, ncpApiInitialized, loading } = useLocale()
  const [returnTo, setReturnTo] = useState('')
  const [isNcp, setIsNcp] = useState(true)
  const [cacheExpiration, setCacheExpiration] = useState('')
  const [instanceSubDomain, setInstanceSubDomain] = useState('')
  const [csrfToken, setCsrfToken] = useState('')
  const addPadding = location.pathname.includes(`${contextPath}/login/${locale}/resets/passwords`)

  const authPageContent = useCachedStoryblok(storyblokApi, brand, locale, 'authPageContent', 'login', cacheExpiration)
  const footerContent = useCachedStoryblok(storyblokApi, brand, locale, 'footerContent', 'footer', cacheExpiration)

  const isLogin = () =>
    location?.pathname === `${contextPath}/login/${locale}/` ||
    location?.pathname === `${contextPath}/login/${locale}`

  const isPasswordReset = () =>
    location?.pathname === `${contextPath}/login/${locale}/resets/passwords` ||
    location.pathname.startsWith(`${contextPath}/login/${locale}/resets/passwords/`)

  const isTroubleshoot = () => location.pathname === `${contextPath}/login/${locale}/troubleshoot`

  const getTitle = () => {
    if (isLogin()) {
      return authPageContent?.page_title
    } else if (isPasswordReset()) {
      return authPageContent?.reset_password_page_title
    } else if (isTroubleshoot()) {
      return authPageContent?.forgot_username_title
    }
    return authPageContent?.not_found_page_title
  }
  const getSubtitle = () => {
    if (isLogin()) {
      return authPageContent?.page_subtitle
    } else if (isPasswordReset()) {
      return authPageContent?.reset_password_page_subtitle
    }
    return null
  };

  const handleBackgroundImage = () => {
    if (isLogin()) {
      return authPageContent?.login_background_image?.filename
    } else {
      return authPageContent?.reset_password_background_image?.filename
    }
  }

  const setFavIcon = (favIconUrl) => {
    const link = document.querySelector("link[rel*='icon']") || document.createElement('link')
    link.type = 'image/x-icon'
    link.rel = 'shortcut icon'
    link.href = favIconUrl
    document.getElementsByTagName('head')[0].appendChild(link)
  }


  useEffect(() => {
    if (brand === 'ncp') {
      setIsNcp(true)
    } else {
      setIsNcp(false)
    }
  }, [brand])

  useEffect(() => {
    const encryptedReturnTo = window.zendeskReturnTo
    decrypt(encryptedReturnTo, API_KEY)
      .then((decrypted) => {
        setReturnTo(decrypted)
      })
      .catch((error) => console.error('Error decrypting key: ', error))
  }, [])

  useEffect(() => {
    const encryptedZendeskSubDomain = window.zendeskInstanceSubDomain
    decrypt(encryptedZendeskSubDomain, API_KEY)
      .then((decrypted) => {
        setInstanceSubDomain(decrypted)
      })
      .catch((error) => console.error('Error decrypting ZD instance subdomain: ', error))
  }, [])


  useEffect(() => {
    const fetchAndInitStoryblok = async () => {
      try {
        if (!ncpApiInitialized) return
        const config = await getConfig()

        localStorage.setItem('storyblokConfig', JSON.stringify(config))

        setCacheExpiration(config?.cache_ttl * 1000)

        const { storyblokApi } = storyblokInit({
          accessToken: config?.cms_key,
          use: [apiPlugin],
          apiOptions: {
            region: 'us'
          }
        })
        setStoryblokApi(storyblokApi)
      } catch (error) {
        console.error('Error fetching config', error)
      }
    }
    fetchAndInitStoryblok()
  }, [cacheExpiration, setStoryblokApi, ncpApiInitialized])

  useEffect(() => {

    if (brand === 'ncp') {
      document.title = brand.toUpperCase()
    } else {
      document.title = captializeFirstLetter(brand)
    }
    if (authPageContent && authPageContent?.favicon?.filename) {
      setFavIcon(authPageContent.favicon.filename)
    }

  }, [brand, authPageContent])

  useEffect(() => {
    const csrfTokenValue = window.csrfToken
    setCsrfToken(csrfTokenValue)
  }, [csrfToken])

  return (
    <>
      <DomainContext.Provider value={{ returnTo, isNcp, instanceSubDomain, csrfToken }}>
        <AuthPageContentContext.Provider value={{ storyblokApi, cacheExpiration, authPageContent, footerContent }}>
          {loading ? <OverlayLoader loading={loading} /> : (
            <>
              <Header />
              <div id='main'>
                <ContentContainer
                  $background={authPageContent && authPageContent?.info_panel_background?.filename
                    && authPageContent.info_panel_background.filename}
                >
                  <HeroContainer $addPadding={addPadding}>
                    <HeroContent
                      $background={authPageContent && handleBackgroundImage()}>
                      <HeroContentInner $isNotFound={getTitle() === authPageContent?.not_found_page_title}>
                        <HeroTitle>
                          {getTitle()}
                        </HeroTitle>
                        <HeroSubtitle>
                          {getSubtitle()}
                        </HeroSubtitle>
                      </HeroContentInner>
                    </HeroContent>
                    <AppContent $isNcp={isNcp}>
                      <Routes>
                        <Route path='/' element={<Navigate replace to={`${contextPath}/login/${locale}`} />} />
                        <Route path={`${contextPath}`} element={<Navigate replace to={`${contextPath}/login/${locale}`} />} />
                        <Route path={`${contextPath}/login/${locale}`} element={<LoginForm />} />
                        <Route path={`${contextPath}/login/:locale/resets/passwords`} element={<PasswordResetForm />} />
                        <Route path={`${contextPath}/login/${locale}/resets/passwords/:token`} element={<NewPasswordForm />} />
                        <Route path={`${contextPath}/login/${locale}/troubleshoot`} element={<LoginTroubleshoot />} />
                        {/* <Route path='*' element={<Navigate replace to={getDefaultRouteForBrand(brand)} />} /> */}
                        <Route path="*" element={<NotFoundPage />} />
                      </Routes>
                    </AppContent>
                  </HeroContainer>
                  <InfoPanel />
                </ContentContainer>
              </div>
              <Footer />
            </>
          )}
        </AuthPageContentContext.Provider>
      </DomainContext.Provider>

    </>
  );
}

export default App;