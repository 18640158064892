import React, { createContext, useState, useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { supportedLocales } from '../utils/helpers'
import { getLoginUrl, initializeApi } from '../../api/ncpApiMethods'

const LocaleContext = createContext({
    locale: 'en-us',
    setLocale: () => { }
})

export const LocaleProvider = ({ children }) => {
    const location = useLocation()
    const navigate = useNavigate()
    const [locale, setLocale] = useState(() => {
        const pathLocale = window.location.pathname.split('/')[3];
        return pathLocale || 'en-us';
    })
    const [ncpApiInitialized, setNcpApiInitialized] = useState(false)
    const [contextPath, setContextPath] = useState('/ncpweb')
    const [brand, setBrand] = useState('')
    const [brandId, setBrandId] = useState('')
    const [loading, setLoading] = useState(true)

    const getQueryParam = (param) => {
        const urlParams = new URLSearchParams(window.location.search)
        return urlParams.get(param)
    }

    useEffect(() => {
        const initialize = async () => {
            try {
                await initializeApi()
                setNcpApiInitialized(true)
            } catch (error) {
                console.error('Failed to initialize API', error)
            }
        }
        initialize()
    }, [])

    useEffect(() => {
        const currentBrandId = getQueryParam('brand_id')
        if (currentBrandId && currentBrandId !== brandId) {
            setBrandId(currentBrandId)
            localStorage.setItem('lastBrandId', currentBrandId)
            setLoading(false)
        }
    }, [location.search, brandId])

    useEffect(() => {
        const redirectToLogin = async () => {
            try {
                const response = await getLoginUrl(brandId)
                if (window.location.href !== response.login_url) {
                    window.location.href = response.login_url
                }
            } catch (error) {
                console.error('Error redirecting user:', error)
            } finally {
                setLoading(false)
            }
        }
        if (brandId && ncpApiInitialized) {
            redirectToLogin()
        }
    }, [brandId, ncpApiInitialized])


    useEffect(() => {
        const contextPathValue = window.contextPath
        setContextPath(contextPathValue)

        const storageData = JSON.parse(localStorage.getItem('appData')) || {}
        storageData.brand = window.brand?.toLowerCase()

        localStorage.setItem('appData', JSON.stringify(storageData))

        setBrand(storageData.brand || window.brand?.toLowerCase())
    }, [brand, contextPath])


    useEffect(() => {
        const urlLocale = location.pathname.split('/')[3]
        const appData = JSON.parse(localStorage.getItem('appData'))
        const currentBrand = appData ? appData.brand : null

        if (currentBrand && supportedLocales[currentBrand]) {
            if (supportedLocales[currentBrand]?.includes(urlLocale)) {
                if (locale !== urlLocale) {
                    setLocale(urlLocale)
                    // console.log(`Locale set and navigating to: ${effectiveLocale}`)
                    navigate(`${contextPath}/login/${urlLocale}`)
                }
            } else {
                const defaultLocale = supportedLocales[currentBrand][0]
                setLocale(defaultLocale)
                // console.log(`Unsupported locale. Navigating to default locale for brand: ${defaultLocale}`);
                navigate(`${contextPath}/login/${defaultLocale}`)
            }
        } else {
            console.error('Brand is not recognized or no brand specified. Falling back to default locale.')
            if (brand === 'ncp' && locale !== 'en-us') {
                setLocale('en-us');
                navigate(`${contextPath}/login/en-us`)
            } else {
                setLocale('en-ca')
                navigate(`${contextPath}/login/en-ca`)
            }
        }
        setLoading(false)
    }, [location, locale, navigate, contextPath, brand])


    return (
        <LocaleContext.Provider value={{ locale, setLocale, brand, contextPath, ncpApiInitialized, loading }}>
            {children}
        </LocaleContext.Provider>
    )
}

export const useLocale = () => useContext(LocaleContext)

export default LocaleContext
