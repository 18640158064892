import { render } from "storyblok-rich-text-react-renderer";

//replacing storyblok domain
export const img = (src) => {
    const config = JSON.parse(localStorage.getItem('storyblokConfig'));
    if (config && config?.cms_asset_url) {
        return (src || '').replace('//a-us.storyblok.com', `//${config?.cms_asset_url}`);
    }
    return (src || '').replace('//a-us.storyblok.com', '//assets.ncponline.net')
}

//zendesk locale map
export const localeMap = {
    1: 'en-us',
    2: 'es-us',
    16: 'fr-ca',
    1181: 'en-ca'
}
//brand locales
export const supportedLocales = {
    ncp: ['en-us', 'es-us'],
    homescan: ['en-ca', 'fr-ca']
}

//captializing the first letter of a string
export const captializeFirstLetter = (str) => {
    if (!str) return str
    return str.charAt(0).toUpperCase() + str.slice(1)
}

export const getErrorMessage = (err, authPageContent, statusContent) => {
    if (err.toString().includes('Access Denied')) {
        return authPageContent?.access_denied_error
    }
    switch (err?.response?.status) {
        case 400:
            return statusContent?.status_400_response;
        case 401:
            return statusContent?.status_401_response;
        case 403:
            return statusContent?.status_403_response;
        case 404:
            return statusContent?.status_404_response;
        case 409:
            return statusContent?.status_409_response;
        case 412:
            return statusContent?.status_412_response;
        case 423:
            return statusContent?.status_423_response;
        case 429:
            return statusContent?.status_429_response;
        case 500:
            return statusContent?.status_500_response;
        default:
            return authPageContent?.error_general_response;
    }
}

//storyblok helpers
const markStyles = {
    textStyle: (content, attrs, index) => (
        <span key={`textStyle-${index}`} style={{ color: attrs?.color }}>{content}</span>
    ),
    highlight: (content, attrs, index) => (
        <span key={`highlight-${index}`} style={{ backgroundColor: attrs?.color }}>{content}</span>
    ),
    italic: (content, index) => <i key={`italic-${index}`}>{content}</i>,
    bold: (content, index) => <strong key={`bold-${index}`}>{content}</strong>,
    strike: (content, index) => (
        <span key={`strike-${index}`} style={{ textDecoration: 'line-through' }}>{content}</span>
    ),
    underline: (content, index) => (
        <span key={`underline-${index}`} style={{ textDecoration: 'underline' }}>{content}</span>
    ),
    superscript: (content, index) => <sup key={`sup-${index}`}>{content}</sup>,
    subscript: (content, index) => <sub key={`sub-${index}`}>{content}</sub>,
    hard_break: (content, index) => <><br key={`break-${index}`} />{content}</>,
    link: (content, attrs, index) => (
        <a key={`link-${index}`} href={attrs.href} target={attrs.target || "_self"}>{content}</a>
    ),
    code: (content, index) => (
        <div key={`code-${index}`} dangerouslySetInnerHTML={{ __html: content }} />
    )
}

const blokResolvers = {
    'Button': (props) => (
        <a
            href={props.link.url}
            target='_blank'
            rel='noopener noreferrer'
            className='button button-primary'>
            {props.text}
        </a>
    ),
    'ImageFromWeb': (props) => (
        <img
            src={props.ImageURL}
            alt={props.alt || 'image'}
            style={{ maxWidth: '100%' }}
        />
    )
}

export const renderContentNode = (document) => {
    return (
        <div>
            {render(document, {
                markResolvers: markStyles,
                blokResolvers
            })}
        </div>
    )
}
